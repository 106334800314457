import logo from "./logo.svg";
import "./App.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import CategorySelectionRow from "./CategorySelectionRow";
import OnboardingDiscoverRow from "./OnboardingDiscoverRow";
import LoadingView from "./utils/LoadingView";

function App() {
	const [newsletterCategories, setNewsletterCategories] = useState(JSON.parse(localStorage.getItem("newsletterCategories")));
	const [selectionArray, setSelectionArray] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [recommendations, setRecommendations] = useState(null);

	const headers = { "Content-Type": "application/json", Authorization: "Token fc955fd7c4695065241c5daadf69952e662d8d77" };

	useEffect(() => {
		if (!localStorage.getItem("newsletterCategories")) {
			fetchCategories();
		}
	}, []);

	function fetchCategories() {
		axios.get("https://www.meco.app/api/newsletters/categories", { headers: headers }).then((response) => {
			localStorage.setItem("newsletterCategories", JSON.stringify(response["data"]["newsletter_categories"]));
			console.log(response);
			setNewsletterCategories(response["data"]["newsletter_categories"]);
		});
	}

	function listItemClicked(categoryId) {
		var currentCategory = [...selectionArray];

		if (currentCategory.includes(categoryId)) {
			currentCategory = currentCategory.filter(function (item) {
				return item !== categoryId;
			});
		} else {
			currentCategory.push(categoryId);
		}

		setSelectionArray(currentCategory);
	}

	function onSimulateClicked() {

		setLoading(true);
		
		let data = { user_categories: selectionArray };

		axios
			.post("https://www.meco.app/api/newsletters/categories", data, { headers: headers })
			.then((response) => {
				axios
					.get("https://www.meco.app/api/newsletters/onboarding?max_recommended_newsletter_count=12", { headers: headers })
					.then((response) => {
						let newsletterArray = response["data"]["newsletters"];
						let newsletter_scores = response["data"]["newsletter_scores"];
			
						var newNewsletterArray = [];
						for (let newsletter of newsletterArray) {
							var mutableNewsletter = newsletter;
							let newsletterId = newsletter.id;
							let score = newsletter_scores.filter(x => x.newsletter_id == newsletterId)[0];
							let scoreCategoryId = score["cat"];
							let categoryName = newsletterCategories.filter(x => x.id == scoreCategoryId)[0].name;
							score["cat"] = categoryName;
							var subcategoryArray = [];
							score["sub_cat"]?.forEach((subcategoryId) => {
								let subcategoryName = newsletterCategories.filter(x => x.id == subcategoryId)[0].name;
								subcategoryArray.push(subcategoryName);
							})
							score["sub_cat"] = subcategoryArray.toString()
							mutableNewsletter["newsletter_score"] = score;
							newNewsletterArray.push(mutableNewsletter);
						}
						let sortedNewsletterArray = newNewsletterArray.sort((a, b) => b.newsletter_score.combined_score - a.newsletter_score.combined_score);
						setRecommendations(sortedNewsletterArray);
						setLoading(false);
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	return (
		<div className="App">
			<LoadingView isLoading={isLoading} loadingMessage={"Tailoring newsletter recommendations just for you"} />
			<div className="CategorySelection">
				<div className="category-selection-pane">
					<div className="select-category-list">
						<div className="container">
							<div className="align-items-center text-center justify-content-center row">
								{newsletterCategories &&
									newsletterCategories.map((categories, i) => {
										return <CategorySelectionRow {...categories} key={i} isChecked={selectionArray.includes(categories.id)} checkOnChange={() => listItemClicked(categories.id)} />;
									})}
							</div>
							<div className="col-md-auto col-centered simulate_button" onClick={() => onSimulateClicked()}>
									Simulate
							</div>
						</div>
					</div>
					<div className="select-discover-list">
						<div className="container">
							{recommendations &&
								recommendations.map((newsletters, i) => {
									return <OnboardingDiscoverRow {...newsletters} key={i} firstItem={i == 0} lastItem={i == recommendations.length - 1} />;
								})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
