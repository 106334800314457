import "./App.scss";

export const CategorySelectionRow = (props) => {
	return (
		<div className="col-md-auto col-centered no-select">
            <div className={props.isChecked ? 'select-category-row selected-category' : 'select-category-row'} onClick={(e) => props.checkOnChange(e, props)} >
                <h2>{props.emoji}&nbsp;&nbsp;{props.name}</h2>
            </div>
		</div>
	);
};

export default CategorySelectionRow;