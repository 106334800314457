import { Modal, Spinner } from 'react-bootstrap';

function LoadingView(props) {

    return (
        <div>
            <Modal show={props.isLoading} aria-labelledby="contained-modal-title-vcenter" centered className="loading-modal-with-text" dialogClassName="loading-modal-dialog" size="sm">
                <Modal.Body>
                    <h4 className="no-select" style={{display: props.loadingMessage != null ? 'block' : 'none' }}>{props.loadingMessage}</h4>
                    <Spinner className="loading-spinner" animation="border" role="status" />
                </Modal.Body>
            </Modal>
        </div>
    )

}

export default LoadingView;