import "./App.scss";
import { Overlay, Tooltip } from "react-bootstrap";

export const OnboardingDiscoverRow = (props) => {

	function rowClicked() {
		window.open(props.subscribe_link, "_blank");
	}

	function RenderButton() {
		if (props.one_click_subscribe_method != null && !props.isChecked) {
			return (
				<div className="plus-button" onClick={(e) => props.checkOnChange(e, props)} style={{float: "right"}}>
					+
				</div>
			);
		}

		if (props.isChecked) {
			return (
				<div className="subscribed-button" onClick={(e) => props.checkOnChange(e, props)}>
					SUBSCRIBED
				</div>
			);
		}

		return (
			<div className="subscribe-button button" onClick={() => rowClicked()}>
				VISIT
			</div>
		);
	}

    return (
        <li key={props.id}>
            <div className="onboarding-discover-container" style={{ borderTopLeftRadius: props.firstItem ? "10px" : "0px", borderTopRightRadius: props.firstItem ? "10px" : "0px", borderBottomLeftRadius: props.lastItem ? "10px" : "0px", borderBottomRightRadius: props.lastItem ? "10px" : "0px" }}>
                <div className="col-7">
                    <div className="container g-0">
                        <div className="row g-0">
                            <div className="col-2 text-center">
                                <img src={props.logo} />
                            </div>
                            <div className={`col-6 align-self-center`}>
                                <h2>{props.name}</h2>
                            </div>
                            <div className={`col-4 align-self-center`}>
                                <RenderButton />
                            </div>
                        </div>
                        <div className="row g-0">
                            <div className="col-12">
                                <h3>{props.summary}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-5">
                    <div className="container">
                        <div className="row">
                        <div className="col-12">
                            <div className="info-container">
                                { Object.keys(props.newsletter_score).map((key, i) => {

                                    return <div className="info"><b>{key} :</b>{props.newsletter_score[key]}</div>

                                    })
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {!props.lastItem && <div className="separator"></div>}
        </li>
    );
};

export default OnboardingDiscoverRow;
